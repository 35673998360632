import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useSiteMetaContext } from '../context/SiteMetaContext';
import { useUserContext } from '../context/UserContext';
import Layout from './Layout';
import Header from '../components/RegisteredHeader';

const Disclaimer = styled.div`
  text-transform: none;
  letter-spacing: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.font.sizes.xxs};
`;

const RegisteredLayout = ({ children, className, location }) => {
  const { hasPurchaseStarted } = useSiteMetaContext();
  const { isPaid } = useUserContext();

  const links = [
    {
      label: 'Day Agendas',
      url: '/agenda',
      highlighted: false,
    },
    {
      label: 'Free Gifts',
      url: '/thankyou#stepGifts',
      highlighted: false,
    },
  ];
  if (hasPurchaseStarted)
    links.push({
      label: isPaid ? (
        'Purchased'
      ) : (
        <div>
          <div>Buy Now</div>
          <Disclaimer>* purchase lifetime access</Disclaimer>
        </div>
      ),
      icon: 'shopping_cart',
      url: '/sales',
      highlighted: true,
    });

  return (
    <Layout className={className}>
      <Header links={links} location={location} />
      <main>{children}</main>
    </Layout>
  );
};

RegisteredLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
  location: PropTypes.object,
};

RegisteredLayout.defaultProps = {
  className: '',
  children: undefined,
  location: undefined,
};

export default RegisteredLayout;
