import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { useSiteMetaContext } from '../context/SiteMetaContext';
import Container from '../layout/Container';
import { useUserContext } from '../context/UserContext';

const StyledHeader = styled.header`
  background-color: ${({ theme }) => theme.colours.backgroundPaper};
`;

const HeaderTitle = styled.h1`
  && {
    color: ${({ theme }) => theme.colours.primary};
    margin: ${({ theme }) => theme.sizes.lvl2} 0;
`;

const HeaderInner = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.sizes.lvl2};
  padding-bottom: ${({ theme }) => theme.sizes.lvl2};
  text-align: center;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  `}
`;

const LogoWrapper = styled.div`
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    margin: 0;
  `}
`;

const StyledNav = styled.nav`
  background-color: ${({ theme }) => theme.colours.backgroundDarkPaper};

  ul {
    padding: 0;
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
`;

const NavLi = styled.li`
  position: relative;
  list-style-type: none;
  margin: 0;
  text-align: center;
  padding: ${({ theme }) => theme.sizes.lvl2};
  border-bottom: ${({ $active, theme }) =>
    $active ? `3px solid ${theme.colours.primary}` : 'none'};
  display: none;

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
      display: flex;
      align-items: center;
      padding: ${theme.sizes.lvl2} ${theme.sizes.lvl4};`}

  ${({ theme }) => theme.breakpoint('desktop')`
      padding: ${theme.sizes.lvl2}  ${theme.sizes.lvl6};`}

      ${({ $highlighted, $purchased }) =>
    $highlighted && !$purchased
      ? `&:hover::before {
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
      }`
      : ``}

  ${({ theme, $highlighted, $purchased }) =>
    $highlighted
      ? `&::before {
      content: '';
      position: absolute;
      transition: box-shadow 0.3s;
      top: -${theme.sizes.lvl1};
      bottom: -${theme.sizes.lvl1};
      border-radius: ${theme.sizes.lvl6};
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
      right: 0;
      left: 0;
      background-color: ${
        $purchased ? theme.colours.lightGray : theme.colours.secondary
      };
    }
      color: ${theme.colours.white} !important;
      display: flex;
      align-items: center;
      ${theme.breakpoint('tabletPortrait')`
          margin-left:  ${theme.sizes.lvl4};
      `}
      ${theme.breakpoint('desktop')`
          margin-left:  ${theme.sizes.lvl6};
      `}
    `
      : ` `}

  a {
    position: relative;
    text-decoration: none;
    color: ${({ theme }) => theme.colours.darkGray};
    font-size: ${({ theme }) => theme.font.sizes.xs};
    ${({ theme }) => theme.breakpoint('desktop')`
      font-size: ${theme.font.sizes.sm};
    `}
    ${({ theme }) => theme.breakpoint('medium')`
    font-size: ${theme.font.sizes.md};
    `}

    ${({ theme, $highlighted }) =>
      $highlighted ? `color: ${theme.colours.white};` : ''}
  }

  span {
    color: ${({ theme }) => theme.colours.white};
    font-size: ${({ theme }) => theme.font.sizes.xl};
    margin-right: ${({ theme }) => theme.sizes.lvl2};
    position: relative;
  }
`;

const Header = ({ links, location }) => {
  const { title, conferenceDateString } = useSiteMetaContext();
  const { isPaid } = useUserContext();

  return (
    <StyledHeader>
      <HeaderInner>
        <LogoWrapper>
          <StaticImage
            src="../images/logo_gray.png"
            width={320}
            alt={title}
            placeholder="tracedSVG"
          />
          <Link to="/"></Link>
        </LogoWrapper>
        <HeaderTitle className="h3">
          <span>FREE &amp; ONLINE</span>
          <br />
          {conferenceDateString}
        </HeaderTitle>
      </HeaderInner>
      <StyledNav>
        <ul>
          {links.map(({ url, highlighted, label, icon }) => (
            <NavLi
              key={`link_${label}`}
              $highlighted={highlighted}
              $purchased={isPaid && highlighted}
              $active={location ? url.indexOf(location.pathname) !== -1 : false}
            >
              {icon && <span className="material-icons">{icon}</span>}

              <Link to={url}>{label}</Link>
            </NavLi>
          ))}
        </ul>
      </StyledNav>
    </StyledHeader>
  );
};

Header.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

Header.defaultProps = {
  links: [],
};

export default Header;
